import React, { useState } from 'react';
import axios from './axiosInstance'; // Assuming axiosInstance is set up correctly
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(''); // ✅ Fixed casing issue
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRegister = async () => {
    setLoading(true);
    setMessage(''); // Reset message on each attempt
    try {
      await axios.post('https://auth.barterout.com/api/auth/register', 
        { username, password }, 
        { headers: { 'Content-Type': 'application/json' } }
      );
      setMessage(t('account_created')); // Success message
      setTimeout(() => navigate('/hero'), 2000); // Redirect after 2 seconds
    } catch (error) {
      setMessage(t('registration_failed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('create_account')}
      </h2>
      <div className="bordered-text" style={{ maxWidth: '500px', padding: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('username')}</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder=""
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('password')}</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder=""
              style={{ flex: 1, padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
            />
            {password && (
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: '5px', padding: '3px', fontSize: '12px', cursor: 'pointer' }}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            )}
          </div>
        </div>
        <button
          onClick={handleRegister}
          disabled={loading}
          style={{
            padding: '3px 10px',
            fontSize: '14px',
            backgroundColor: '#E6E6E6',
            border: '1px solid #000000',
            cursor: 'pointer',
          }}
        >
          {t('create_account')}
        </button>
        {message && (
          <p style={{ marginTop: '10px', fontSize: '14px', color: message === t('account_created') ? 'green' : 'red' }}>
            {message}
          </p>
        )}
        <p style={{ marginTop: '20px', fontSize: '14px' }}>
          {t('already_have_account')} <Link to="/login">{t('sign_in')}</Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
