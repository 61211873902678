import React, { useState } from 'react';
import axios from './axiosInstance'; // Assuming axiosInstance is set up correctly
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://auth.barterout.com/api/auth/login', 
        { username, password }, 
        { headers: { 'Content-Type': 'application/json' } }
      );
      localStorage.setItem('token', response.data.token); // Corrected this line
      setMessage(t('logged_in'));
      navigate('/browse');
    } catch (error) {
      setMessage(error.message.includes('Network Error') ? 'Login Failed' : t('login_failed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('sign_in')}
      </h2>
      <div className="bordered-text" style={{ maxWidth: '500px', padding: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('username')}</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder=""
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('password')}</label>
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder=""
              style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
            />
            {password && (
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '12px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            )}
          </div>
        </div>
        <button
          onClick={handleLogin}
          disabled={loading}
          style={{
            padding: '3px 10px',
            fontSize: '14px',
            backgroundColor: '#E6E6E6',
            border: '1px solid #000000',
            cursor: 'pointer',
          }}
        >
          {t('sign_in')}
        </button>
        {message && (
          <p style={{ marginTop: '10px', fontSize: '14px', color: message === t('logged_in') ? 'green' : 'red' }}>
            {message}
          </p>
        )}
        <p style={{ marginTop: '20px', fontSize: '14px' }}>
          {t('new_to_barterout')} <Link to="/register">{t('create_account')}</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;

