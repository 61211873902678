import React, { useState, useEffect } from 'react';
import axios from './axiosInstance';
import { Link } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';

function Suggestions() {
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const res = await axios.get('https://auth.barterout.com/api/auth/trade-suggestions', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSuggestions(res.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
    fetchSuggestions();
  }, []);

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <Breadcrumb />
      <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('trade_suggestions')}
      </h2>
      <ul>
        {suggestions.map((item) => (
          <li key={item._id} style={{ marginBottom: '5px', fontSize: '14px' }}>
            <span style={{ color: '#888888', marginRight: '10px' }}>
              {new Date(item.createdAt).toLocaleDateString()}
            </span>
            <Link to={`/item/${item._id}`}>{item.name}</Link>
            <span style={{ marginLeft: '10px', color: '#888888' }}>
              ({item.category})
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Suggestions;
